<template>
  <v-container class="pa-4">
    <v-row>
        <v-card-title class="px-4">Purchase Summary</v-card-title>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="Picker in dialog"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
            
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate()"
              >
                Get Report
              </v-btn>
            <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generateExcel()"
              >
                Generate Excel
              </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="data"
                class="elevation-1"
                style="border: 0.5px solid black;"
            >
                <template v-slot:item.report_hour="{ item }">
                    {{ formatHour(item.report_hour) }}
                </template>
                <template v-slot:item.sales_number="{ item }">
                    {{ formatPrice(item.sales_number) }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import reportingApi from '../../clients/reportingApi';
// @ is an alias to /src
export default {
  name: "Ticket",
  data() {
    return{
        dialog: false,
        modal: false,
        date: null,
        headers: [
          {
            text: 'Date',
            align: 'center',
            sortable: false,
            value: 'report_date',
          },
          {
            text: 'Time Activities',
            align: 'center',
            sortable: false,
            value: 'report_hour',
          },
          {
            text: 'Number Visitor',
            align: 'center',
            sortable: false,
            value: 'traffic',
          },
          {
            text: 'Number of Sales',
            align: 'center',
            sortable: false,
            value: 'sales_total',
          },
          {
            text: 'Total Sales',
            align: 'center',
            sortable: false,
            value: 'sales_number',
          },
        ],
        data: [],
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
  },
  methods: {
    formatPrice(value) {
      console.log(value);
      let val = (value / 1).toFixed(0).replace('.', ',')
      return 'Rp.' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatHour(value){
        if(value >= 10){
            return value + ':00';
        }
        return '0' + value + ':00';
    },
    async generateExcel(){
      let queryObject = [];
      if(![null, undefined].includes(this.date))
      {
        queryObject = [
            {
                name: "date",
                value: this.date
            },
        ]
      }
      const baseUrl =  reportingApi.getUrl();
      const queryString = reportingApi.generateQueryString(queryObject);
      let url = baseUrl + 'v1/report/generate-excel?purpose=purchase-summary' + (queryString !== '' ? `&${queryString}` : '');
      console.log(url);
      try{
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xlsx');
        document.body.appendChild(link);
        link.click();
      }
      catch(err){
        console.log(err)
      }
    },
    async generate(){
      console.log(this.date)
      let queryObject = [];
      if(![null, undefined].includes(this.date))
      {
        queryObject = [
            {
                name: "date",
                value: this.date
            },
        ]
      }
      let route = 'v1/traffic/purchase-summary?'
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      if(![undefined, null].includes(data)){
        if(data.length > 0){
          this.data = data;
        }
      }
    },
  },
};
</script>
