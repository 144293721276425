<template>
  <v-container class="pa-4">
    <v-row>
        <v-card-title class="px-4">Data: By Locations</v-card-title>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dateFilter"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilterString"
                    label="Period Filter"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFilter" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveDate(dateFilter)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
            
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate()"
              >
                Get Report
              </v-btn>
            <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generateExcel()"
              >
                Generate Excel
              </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="data"
                class="elevation-1"
                style="border: 0.5px solid black;"
                hide-default-header
            >
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th rowspan="2" class="text-center" style="border: 0.5px solid black;">{{ props.headers[0].text }}</th>
                            <th colspan="2" class="text-center" style="border: 0.5px solid black;">Analytics</th>
                            <th rowspan="2" class="text-center" style="border: 0.5px solid black;">{{ props.headers[3].text }}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="border: 0.5px solid black;">{{ props.headers[1].text }}</th>
                            <th class="text-center" style="border: 0.5px solid black;">{{ props.headers[2].text }}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item.name="{ item }">
                    <td class="text-start">
                        {{ item.name }}
                    </td>
                </template>
                <!-- <template v-slot:item.calories="{ item }">
                <v-chip
                    :color="getColor(item.calories)"
                    dark
                >
                    {{ item.calories }}
                </v-chip>
                </template> -->
            </v-data-table>
        </v-col>
        <v-col cols="12" md="12" v-if="showChart">
             <div id="chart">
                <apexchart type="bar" height="600" :options="chartOptions" :series="series" v-if="showChart"></apexchart>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  components: {
          apexchart: VueApexCharts,
        },
  data() {
    return{
        dialog: false,
        modal: false,
        dateFilterString: '',
        dateFilter: null,
        showChart: false,
        date: null,
        headers: [
          {
            text: 'Area',
            align: 'center',
            sortable: false,
            value: 'area',
          },
          {
            text: 'Website Traffic',
            align: 'center',
            sortable: false,
            value: 'website_traffic',
          },
          {
            text: 'Purchase Page Traffic (Not Checkout)',
            align: 'center',
            sortable: false,
            value: 'purchase_page_traffic',
          },
          {
            text: 'SOLD',
            align: 'center',
            sortable: false,
            value: 'sold',
          },
        ],
        data: [],
        series: [{
            name: 'Website Traffic',
            data: []
          },  {
            name: 'Purchase Page Traffic (Not Checkout)',
            data: []
          },
          {
            name: 'Ticket Sold',
            data: []
          },],

          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: 'Traffic'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return  val + " Users"
                }
              }
            }
          },
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
  },
  methods: {
    sortDate(dates) {
      if(dates.length === 0){
        return "";
      }
      const localDates = dates.sort((a, b) => {
        a = a.split("-").join("");
        b = b.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
      });
      return localDates;
    },
    saveDate(dates){
      console.log(dates);
      console.log(this.$refs)
      this.dateFilterString = this.sortDate(dates).join(" ~ ");
      console.log(this.$refs)
      console.log(this.$refs.dialog)

      this.$refs['dialog'].save(dates);
    },
    async generateExcel(){
      var BASE_URL;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("API PRODUCTION ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API;
      } else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("API TEST ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_TEST;
      } else {
        console.log("API LOCAL ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_LOCAL;
      }

      let url = BASE_URL + 'v1/report/generate-excel?purpose=by-area'
      console.log(url);
        try{
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xlsx');
        document.body.appendChild(link);
        link.click();
        }
        catch(err){
          console.log(err)
        }
    },
    async generate(){
      let queryObject = [];
      let route = 'v1/traffic/by-area?'
      console.log(route, queryObject)
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      console.log(data);
      console.log([undefined, null].includes(data));
      if(![undefined, null].includes(data)){
        if(data.length > 0){
           var number = 0;
           var limit = 20;
           data.forEach((item) => {
            if(number === limit){
              return;
            }
            console.log(item)
            this.chartOptions.xaxis.categories.push(item.area)
            this.series[0].data.push(item.website_traffic)
            this.series[1].data.push(item.purchase_page_traffic)
            this.series[2].data.push(item.sold)
            number++
          })
          this.data = data; //Table
          this.showChart = true //Chart
          console.log(this.chartOptions)
          console.log(this.series)
          //ToDo: Load More
        }
      }
    },
  },
};
</script>
