"use strict";
// const ipfsClient = require('ipfs-http-client')
import axios from "axios";
// const request = require('request')
// import store from '@/store/index'

var BASE_URL;
const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};
if (process.env.VUE_APP_ENVIRONMENT === "production") {
  console.log("API PRODUCTION ENVIRONMENT");
  BASE_URL = process.env.VUE_APP_BASE_URL_API;
} else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
  console.log("API TEST ENVIRONMENT");
  BASE_URL = process.env.VUE_APP_BASE_URL_API_TEST;
} else {
  console.log("API LOCAL ENVIRONMENT");
  BASE_URL = process.env.VUE_APP_BASE_URL_API_LOCAL;
}

let getters = null;
class reportingApi {
  constructor() {
    getters = getters || this;
    return getters;
  }

  getUrl(){
    return BASE_URL;
  }

  generateQueryString(queryObject) {
    if(queryObject.length === 0){
      return '';
    }
    let queryStrings = [];
    queryObject.forEach(item => {
      queryStrings.push(item.name + '=' + item.value)
    })
    return queryStrings.join('&');
  }

  get(route, queryObject) {
    let queryStrings = [];
    queryObject.forEach(item => {
      queryStrings.push(item.name + '=' + item.value)
    })
    const requestUrl = BASE_URL + route + queryStrings.join('&');
    console.log(requestUrl);
    return axios
      .get(requestUrl, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export default new reportingApi();