<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.id">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <v-card color="basil" flat>
          <v-card-title class="px-0">Data: {{ item.name }}</v-card-title>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="value[index]"
                :items="events"
                chips
                item-text="name"
                item-value="id"
                outlined
                solo
                return-object
                flat
                :placeholder="textPlaceholder[index]"
                label="Events"
                :multiple="isMultiple[index]"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                :ref="'dialog' + index"
                v-model="modal[index]"
                :return-value.sync="item.dateFilter"
                persistent
                width="290px"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.dateFilterString"
                    label="Period Filter"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.dateFilter" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = [false, false]">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveDate(index, item.dateFilter)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            
            <!-- <v-col cols="12" md="3" v-if="item.id === 2">
                <v-select
                  v-model="events"
                  :items="eventList"
                  label="Select"
                  multiple
                  hint="Pick events"
                  persistent-hint
                ></v-select>
            </v-col> -->
            <v-col cols="12" md="12" class="d-md-flex justify-end">
                              <!-- :outlined="$vuetify.breakpoint.mdAndUp"
                :block="!$vuetify.breakpoint.mdAndUp" -->
              <!-- <span>{{ value[index] }}</span> -->
              <div class="d-flex flex-column" v-if="value[index] !== null">
                <v-span class="mb-2">
                    <v-chip label color="green" outlined small>
                      <v-icon left small>
                        mdi-check-decagram-outline
                      </v-icon>
                      Recommended</v-chip>
                </v-span>
                <div class="d-flex flex-column" v-if="index === 0">  
                  <span v-for="(val, idx) in value[index]" :key="idx" >{{ val.name + ' Filter : ' + formatDate(val.created_at, val.event_date) }}</span>
                </div>
                <div v-else>
                  {{ value[index].name + ' Filter : ' + formatDate(value[index].created_at, value[index].event_date) }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate(index)"
              >
                Get Report
              </v-btn>
              <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generateExcel(index)"
              >
                Generate Excel
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" >
                              <!-- :outlined="$vuetify.breakpoint.mdAndUp"
                :block="!$vuetify.breakpoint.mdAndUp" -->
              
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-8">
          <v-data-table
            :headers="headers[index]"
            :items="index === 0 ? data : summary"
            :items-per-page="5"
            class="elevation-1 pt-4"
          ></v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
// @ is an alias to /src
import reportingApi from '../../clients/reportingApi';
import {makeDate} from '../../helper/makeDate'
export default {
  name: "Ticket",
  data() {
    return {
      tab: null,
      modal: [false, false],
      events: [],
      value: [null, null],
      isMultiple: [true, false],
      textPlaceholder: ['Select 1 or more Events', 'Select 1 event'],
      eventList: ["Ne-Yo", "Kehlani"],
      items: [{id: 1, name: "By event and ticket categories", dateFilter: [], dateFilterString: ''}, {id:2, name:"Tickets summary", dateFilter: [], dateFilterString: ''}],
      headers: [
        [
          { text: "Event Name", value: "event_name" },
          { text: "Period", value: "period" },
          { text: "Traffic To Home", value: "traffic_home" },
          { text: "Traffic To Product Page", value: "traffic_product" },
          { text: "Ticket Sold", value: "ticket_sold" },
          { text: "Unfinished Transactions", value: "unfinished_transactions" },
        ],
        [
          { text: "Event Name", value: "event_name" },
          { text: "Ticket Type", value: "ticket_type" },
          { text: "Period", value: "period" },
          { text: "Stock", value: "stock" },
          { text: "Sold", value: "sold" },
          { text: "Remaining", value: "remaining" },
          // { text: "Issued Ticket", value: "issued_ticket" },
        ],
      ],
      data:[],
      summary:[],
    };
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }

    this.getEvents();
  },
  methods: {
    saveDate(index, dates){
      console.log(dates);
      console.log(this.$refs)
      this.items[index].dateFilterString = this.sortDate(dates).join(" ~ ");
      this.$refs['dialog'+index][0].save(dates);
    },
    sortDate(dates) {
      if(dates.length === 0){
        return "";
      }
      const localDates = dates.sort((a, b) => {
        a = a.split("-").join("");
        b = b.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
      });
      return localDates;
    },
    getEvents () {  
      let route = 'v1/ticket/getevents'
      let queryObject = []
      Promise.resolve(reportingApi.get(route, queryObject))
        .then(result => {
          console.log(result) // result.data
          this.events = result.data.data
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting Fighter info.', err)
          // show friendly error in user screen 
        })
    },
    async generateExcel(index){
      var BASE_URL;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("API PRODUCTION ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API;
      } else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("API TEST ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_TEST;
      } else {
        console.log("API LOCAL ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_LOCAL;
      }
      let purpose = '';
      const item = this.items[index];
      let queryObject = [
        {
          name: "start",
          value: item.dateFilter[0]
        },
        {
          name:"end",
          value: item.dateFilter[1] ?? item.dateFilter[0]
        },
      ]
      console.log(queryObject);
      switch(index) {
        case 0:{
          purpose = 'event-and-categories'
          const ids = [];
          this.value[index].forEach(val => {
            ids.push(val.BSC_contract_id ?? val.ETH_contract_id ?? val.MATIC_contract_id ?? -1)
          })
          queryObject.push(
            {
              name:"eventId",
              value: ids
            }
          )
          break;
        }
        case 1:{
          purpose = 'summary'
          queryObject.push(
            {
              name:"eventId",
              value: this.value[index].BSC_contract_id ?? this.value[index].ETH_contract_id ?? this.value[index].MATIC_contract_id
            }
          )
          break;
        }
        default:
          break;
      }

      let queryStrings = [];
      queryObject.forEach(item => {
        queryStrings.push(item.name + '=' + item.value)
      })
      console.log(queryStrings)

      let url = BASE_URL + `v1/report/generate-excel?purpose=${purpose}` + '&' + queryStrings.join('&')
      console.log(url);
        try{
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xlsx');
        document.body.appendChild(link);
        link.click();
        }
        catch(err){
          console.log(err)
        }
    },
    async generate(index){
      // console.log('value', this.value[index]);
      const item = this.items[index];
      let route;
      let queryObject = [
        {
          name: "start",
          value: item.dateFilter[0]
        },
        {
          name:"end",
          value: item.dateFilter[1] ?? item.dateFilter[0]
        },
      ]
      console.log(queryObject);
      switch(index) {
        case 0:{
          route = 'v1/ticket/event-and-categories?'
          const ids = [];
          this.value[index].forEach(val => {
            ids.push(val.BSC_contract_id ?? val.ETH_contract_id ?? val.MATIC_contract_id ?? -1)
          })
          queryObject.push(
            {
              name:"eventId",
              value: ids
            }
          )
          break;
        }
        case 1:{
          route = 'v1/ticket/summary?'
          queryObject.push(
            {
              name:"eventId",
              value: this.value[index].BSC_contract_id ?? this.value[index].ETH_contract_id ?? this.value[index].MATIC_contract_id
            }
          )
          break;
        }
        default:
          break;
      }
      console.log(route, queryObject)
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      console.log(data);
      console.log([undefined, null].includes(data));
      if(![undefined, null].includes(data)){
        if(data.length > 0){
          data.forEach(itemData => {
            itemData['period'] = item.dateFilter[0] + ' ~ ' + item.dateFilter[1]
          })
        }
      }

      if(index === 0){
        this.data = data;
      }else{
        this.summary = data;
      }
    },
    formatDate(dateStart, dateEnd){
      return makeDate(dateStart) + '(Ticket Sales Started) ~ ' + makeDate(dateEnd) + ' (Event Started)';
    }
  },
};
</script>
