<template>
  <v-container class="pa-4">
    <v-row>
        <v-card-title class="px-4">Event Comparison</v-card-title>
    </v-row>
    <v-row>
        <v-col cols="12" md="5">
            <v-select
                v-model="value1"
                :items="events1"
                chips
                item-text="name"
                item-value="id"
                outlined
                solo
                return-object
                flat
                :placeholder="textPlaceholder1"
                label="Event 1"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-center">
            <span class="text-h6 mt-3">COMPARE WITH</span>
        </v-col>
        <v-col cols="12" md="5">
            <v-select
                v-model="value2"
                :items="events2"
                chips
                item-text="name"
                item-value="id"
                outlined
                solo
                return-object
                flat
                :placeholder="textPlaceholder2"
                label="Event 2"
                :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dateFilter"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilterString"
                    label="Period Filter"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFilter" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveDate(dateFilter)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate()"
              >
                Get Report
              </v-btn>
            <!-- <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generate()"
              >
                Generate Excel
              </v-btn> -->
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">

      <v-data-table
          :headers="headers"
          :items="data"
          class="elevation-1"
          style="border: 0.5px solid black;"
        >
          <template v-slot:item.report_hour="{ item }">
              {{ formatHour(item.report_hour) }}
          </template>
          <template v-slot:item.sales_number="{ item }">
              {{ formatPrice(item.sales_number) }}
          </template>
      </v-data-table>
        </v-col>

    </v-row>
  </v-container>
</template>
<script>
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  data() {
    return{
        events: [],
        value1: null,
        value2: null,
        textPlaceholder1: 'Choose First Event',
        textPlaceholder2: 'Choose Second Event',
        dialog: false,
        modal: false,
        date: null,
        dateFilterString: '',
        dateFilter: null,
        headers: [
          {
            text: 'Event Name',
            align: 'center',
            sortable: false,
            value: 'event_name',
          },
          {
            text: 'Ticket Type',
            align: 'center',
            sortable: false,
            value: 'ticket_type',
          },
          {
            text: 'Traffic on Page',
            align: 'center',
            sortable: false,
            value: 'traffic',
          },
          {
            text: 'Sales',
            align: 'center',
            sortable: false,
            value: 'sales',
          },
          {
            text: 'Conversion',
            align: 'center',
            sortable: false,
            value: 'conversion',
          },
        ],
        data: [],
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    },
    events1(){
        return this.events.filter((event) => {return event !== this.value2})
    },
    events2(){
        return this.events.filter((event) => {return event !== this.value1})
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
    this.getEvents();
  },
  methods: {
    getEvents () {  
      let route = 'v1/ticket/getevents'
      let queryObject = []
      Promise.resolve(reportingApi.get(route, queryObject))
        .then(result => {
          console.log(result) // result.data
          this.events = result.data.data
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting Fighter info.', err)
          // show friendly error in user screen 
        })
    },
    sortDate(dates) {
      if(dates.length === 0){
        return "";
      }
      const localDates = dates.sort((a, b) => {
        a = a.split("-").join("");
        b = b.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
      });
      return localDates;
    },
    saveDate(dates){
      this.dateFilterString = this.sortDate(dates).join(" ~ ");
      this.$refs['dialog'].save(dates);
    },
    async generate(){
      console.log(this.dateFilter)
      console.log(this.value1)
      let queryObject = [];
      if(![null, undefined].includes(this.dateFilter))
      {
        queryObject = [
          {
            name: "start",
            value: this.dateFilter[0]
          },
          {
            name:"end",
            value: this.dateFilter[1] ?? this.dateFilter[0]
          },
          {
            name:"eventId",
            value: [this.value1.id, this.value2.id]
          }
        ]
      }
      let route = 'v1/traffic/event-comparison?'
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      if(![undefined, null].includes(data)){
        if(data.length > 0){
          this.data = data;
        }
      }
    },
  },
};
</script>
