<template>
  <v-container class="pa-4">
    <v-row>
        <v-card-title class="px-4">Market Report</v-card-title>
    </v-row>
        <v-row>
        <v-col cols="12" md="12">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dateFilter"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilterString"
                    label="Period Filter"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFilter" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveDate(dateFilter)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
            
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate()"
              >
                Get Report
              </v-btn>
            <!-- <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generate()"
              >
                Generate Excel
              </v-btn> -->
        </v-col>
    </v-row>
    <v-row v-if="showChart">
        <v-col cols="12" md="6" >
             <div id="chart-1">
                <apexchart type="bar" height="400" :options="chartOptionsAge" :series="seriesAge" @dataPointSelection="ageClickHandler" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6">
             <div id="chart-2">
                <apexchart type="pie" height="400" :options="chartOptionsGender" :series="seriesGender" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6" >
             <div id="chart-3">
                <apexchart type="bar" height="400" :options="chartOptionsLocation" :series="seriesLocation" @dataPointSelection="locationClickHandler" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6" >
             <div id="chart-3">
                <apexchart type="bar" height="400" :options="chartOptionsPayment" :series="seriesPayment" @dataPointSelection="paymentClickHandler" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="12">
             <div id="chart-2">
                <apexchart type="bar" height="600" :options="chartOptionsAveragePurchase" :series="seriesAveragePurchase" v-if="showChart"></apexchart>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  components: {
          apexchart: VueApexCharts,
        },
  data() {
    return{
        index: 0,
        dialog: false,
        modal: false,
        dateFilterString: '',
        dateFilter: null,
        date: null,
        showChart: false,
        filter: null,
        seriesAge: [{
            data: []
        }],
        seriesPayment: [{
            data: []
        }],
        chartOptionsAge: {
            title:{
              text:"Age"
            },
            chart: {
              type: 'bar',
              height: 400
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
         chartOptionsPayment: {
            title:{
              text:"Payment Method"
            },
            chart: {
              type: 'bar',
              height: 400,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
        seriesGender: [],
        chartOptionsGender: {
            title:{
              text:"Gender"
            },
            chart: {
              type: 'pie',
              height: 400,
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                 tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: []
                },
              }
            },
            labels: ['Male', 'Female', 'Uknown'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: '100%'
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
        },
        seriesAveragePurchase: [{
            data: []
        }],
        chartOptionsAveragePurchase: {
            title:{
              text:"Average Purchase"
            },
            chart: {
              type: 'bar',
              height: 600
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
        seriesLocation: [{
            name: 'Location',
            data: []
          }],
        chartOptionsLocation: {
          title:{
              text:"Location"
            },
            chart: {
              type: 'bar',
              height: 400
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
              }
            }
          },
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
  },
  methods: {
    sortDate(dates) {
      if(dates.length === 0){
        return "";
      }
      const localDates = dates.sort((a, b) => {
        a = a.split("-").join("");
        b = b.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
      });
      return localDates;
    },
    saveDate(dates){
      console.log(dates);
      console.log(this.$refs)
      this.dateFilterString = this.sortDate(dates).join(" ~ ");
      console.log(this.$refs)
      console.log(this.$refs.dialog)
      this.$refs['dialog'].save(dates);
    },
    init(){
      this.showChart = false;
      this.seriesAge[0].data = [];
      this.chartOptionsAge.xaxis.categories = [];
      this.seriesGender = [];
      this.chartOptionsAveragePurchase.xaxis.categories = [];
      this.seriesAveragePurchase[0].data = [];      
      this.seriesPayment[0].data = [];
      this.chartOptionsPayment.xaxis.categories = [];
      this.seriesLocation[0].data = [];
      this.chartOptionsLocation.xaxis.categories = [];
    },
    formatPrice(value) {
      console.log(value);
      let val = (value / 1).toFixed(0).replace('.', ',')
      return 'Rp.' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async generate(){
      this.init();
      let queryObject = [];
      if(![null, undefined].includes(this.dateFilter))
      {
        queryObject = [
            {
            name: "start",
            value: this.dateFilter[0]
            },
            {
            name:"end",
            value: this.dateFilter[1] ?? this.dateFilter[0]
            },
        ]
      }
      let route = 'v1/market/user-demographic?'
      const response = await reportingApi.get(route, queryObject)
      const result = response.data.result;
      if(![undefined, null].includes(result)){
        var ageData = result[0].data;
        if(ageData.length > 0){
            ageData.forEach(item => {
                this.chartOptionsAge.xaxis.categories.push(item.age == -1 ? 'Uknown Age' : 'Age of ' + item.age);
                this.seriesAge[0].data.push(item.age == -1 ? 2 : item.total)
            })
        }

        var paymentData = result[3].data;
        if(paymentData.length > 0){
            paymentData.forEach(item => {
                this.chartOptionsPayment.xaxis.categories.push(item.payment_method == -1 ? 'Uknown Payment' : item.payment_method);
                this.seriesPayment[0].data.push(item.count)
            })
        }

        var genderData = result[1].data;
        if(genderData.length > 0){
            let arrGender = [Number.parseInt(genderData[0].male) ?? 0, Number.parseInt(genderData[0].female) ?? 0, 
            Number.parseInt(genderData[0].uknown) ?? 0];
            this.seriesGender = [...arrGender];
            console.log(this.seriesGender)
        }

        var averagePurchaseData = result[2].data;
        console.log("avg", averagePurchaseData)
        if(averagePurchaseData.length > 0){
            averagePurchaseData.forEach(item => {
              this.chartOptionsAveragePurchase.xaxis.categories.push(item.amount.includes("below") ? "Below " + this.formatPrice(item.amount.split(" ")[1]) : this.formatPrice(item.amount));
              this.seriesAveragePurchase[0].data.push(Number.parseInt(item.purchase_amount) ?? 0)
            })
              console.log("abc", this.chartOptionsAveragePurchase.xaxis.categories)
              console.log("abc", this.seriesAveragePurchase)
              console.log("abc", this.seriesAveragePurchase[0])
              console.log("abc", this.seriesAveragePurchase[0].data)
        }

        var locationData = result[4].data;
        if(locationData.length > 0){
          locationData.forEach(item => {
                this.chartOptionsLocation.xaxis.categories.push(item.city);
                this.seriesLocation[0].data.push(item.total_count)
            })
        }
      }
      this.showChart = true;
    },
    getFilter(index){
      console.log('a', index);
      let filter = {
        purpose: "payment-method",
        label: this.chartOptionsPayment.xaxis.categories[index],
        data: this.seriesPayment[index].data
      }
      console.log(filter);
    },
    paymentClickHandler: function(event, chartContext, config) {      
      console.log(event, chartContext, config)  
      var index = config.dataPointIndex
      let filter = {
        purpose: "payment-method",
        label: this.chartOptionsPayment.xaxis.categories[index],
        date: [undefined, null, ""].includes(this.dateFilterString) ? "xxx" : this.dateFilterString.replace(/ /g, ""),
        value: this.seriesPayment[0].data[index]
      }
      const param = [filter.purpose, filter.label, filter.date, filter.value];
      console.log(param.join("+"))
      this.$router.push('/market/filter/' + param.join("+"));
    },
    ageClickHandler: function(event, chartContext, config) {      
      console.log(event, chartContext, config)  
      var index = config.dataPointIndex
      let filter = {
        purpose: "age",
        label: this.chartOptionsAge.xaxis.categories[index] === "Uknown Age" ? -1 : Number.parseInt(this.chartOptionsAge.xaxis.categories[index].split(" ")[2]),
        date: [undefined, null, ""].includes(this.dateFilterString) ? "xxx" : this.dateFilterString.replace(/ /g, ""),
        value: this.seriesAge[0].data[index]
      }

      const param = [filter.purpose, filter.label, filter.date, filter.value];
      console.log(param.join("+"))
      this.$router.push('/market/filter/' + param.join("+"));
    },
    locationClickHandler: function(event, chartContext, config) {  
      console.log(event, chartContext, config)  
      var index = config.dataPointIndex
      let filter = {
        purpose: "location",
        label: this.chartOptionsLocation.xaxis.categories[index],
        date: [undefined, null, ""].includes(this.dateFilterString) ? "xxx" : this.dateFilterString.replace(/ /g, ""),
        value: this.seriesLocation[0].data[index]
      }
      const param = [filter.purpose, filter.label, filter.date, filter.value];
      console.log(param.join("+"))
      this.$router.push('/market/filter/' + param.join("+"));
    },
  },
};
</script>
