<template>
  <v-container class="pa-4">
    <v-row>
        <v-card-title class="px-4">Top Spender</v-card-title>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dateFilter"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilterString"
                    label="Period Filter"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFilter" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveDate(dateFilter)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
            
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
                outlined
                :block="!$vuetify.breakpoint.mdAndUp"
                class="mr-md-4 mb-6 my-md-0"
                color="primary"
                @click="generate()"
              >
                Get Report
              </v-btn>
            <v-btn
                outlined
                class="mt-6 my-md-0"
                :block="!$vuetify.breakpoint.mdAndUp"
                color="success"
                @click="generateExcel()"
              >
                Generate Excel
              </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="data"
                class="elevation-1"
                style="border: 0.5px solid black;"
            >
                <template v-slot:item.total_spending="{ item }">
                    {{ formatPrice(item.total_spending) }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  data() {
    return{
        dialog: false,
        modal: false,
        dateFilterString: '',
        dateFilter: null,
        date: null,
        headers: [
          {
            text: 'User Name',
            align: 'center',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Period Date',
            align: 'center',
            sortable: false,
            value: 'period',
          },
          {
            text: 'Total Spending',
            align: 'center',
            sortable: false,
            value: 'total_spending',
          },
          {
            text: 'Total Tickets',
            align: 'center',
            sortable: false,
            value: 'total_tickets',
          },
        ],
        data: [],
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
  },
  methods: {
    sortDate(dates) {
      if(dates.length === 0){
        return "";
      }
      const localDates = dates.sort((a, b) => {
        a = a.split("-").join("");
        b = b.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
      });
      return localDates;
    },
    saveDate(dates){
      console.log(dates);
      console.log(this.$refs)
      this.dateFilterString = this.sortDate(dates).join(" ~ ");
      console.log(this.$refs)
      console.log(this.$refs.dialog)
      this.$refs['dialog'].save(dates);
    },
    formatPrice(value) {
      console.log(value);
      let val = (value / 1).toFixed(0).replace('.', ',')
      return 'Rp.' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async generateExcel(){
      let queryObject = [];
      if(![null, undefined].includes(this.dateFilter))
      {
        queryObject = [
            {
            name: "start",
            value: this.dateFilter[0]
            },
            {
            name:"end",
            value: this.dateFilter[1] ?? this.dateFilter[0]
            },
        ]
      }
      const baseUrl =  reportingApi.getUrl();
      const queryString = reportingApi.generateQueryString(queryObject);
      let url = baseUrl + 'v1/report/generate-excel?purpose=top-spender' + (queryString !== '' ? `&${queryString}` : '');
      console.log(url);
      try{
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xlsx');
        document.body.appendChild(link);
        link.click();
      }
      catch(err){
        console.log(err)
      }
    },
    async generate(){
      console.log(this.dateFilter)
      let queryObject = [];
      if(![null, undefined].includes(this.dateFilter))
      {
        queryObject = [
            {
            name: "start",
            value: this.dateFilter[0]
            },
            {
            name:"end",
            value: this.dateFilter[1] ?? this.dateFilter[0]
            },
        ]
      }

      let route = 'v1/traffic/top-spender?'
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      if(![undefined, null].includes(data)){
        if(data.length > 0){
          this.data = data;
        }
      }
    },
  },
};
</script>
