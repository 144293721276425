<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn @click.stop="generate()" outlined block
                color="primary"
        >Generate</v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn @click.stop="generateExcel()" outlined block
                color="success"
        >Generate Excel</v-btn>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="data"
                class="elevation-1"
                style="border: 0.5px solid black;"
                hide-default-header
            >
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th rowspan="2" class="text-center" style="border: 0.5px solid black;">{{ props.headers[0].text }}</th>
                            <th colspan="2" class="text-center" style="border: 0.5px solid black;">Analytics</th>
                            <th rowspan="2" class="text-center" style="border: 0.5px solid black;">{{ props.headers[3].text }}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="border: 0.5px solid black;">{{ props.headers[1].text }}</th>
                            <th class="text-center" style="border: 0.5px solid black;">{{ props.headers[2].text }}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item.name="{ item }">
                    <td class="text-start">
                        {{ item.name }}
                    </td>
                </template>
                <!-- <template v-slot:item.calories="{ item }">
                <v-chip
                    :color="getColor(item.calories)"
                    dark
                >
                    {{ item.calories }}
                </v-chip>
                </template> -->
            </v-data-table>
        </v-col>
        <v-col cols="12" md="12" v-if="showChart">
             <div id="chart">
                <apexchart type="line" height="500" :options="chartOptions" :series="series" v-if="showChart"></apexchart>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  components: {
          apexchart: VueApexCharts,
        },
  data() {
    return{
        showChart: false,
        headers: [
          {
            text: 'Period',
            align: 'center',
            sortable: false,
            value: 'time_period',
          },
          {
            text: 'Website Traffic',
            align: 'center',
            sortable: false,
            value: 'website_traffic',
          },
          {
            text: 'Purchase Page Traffic (Not Checkout)',
            align: 'center',
            sortable: false,
            value: 'purchase_page_traffic',
          },
          {
            text: 'SOLD',
            align: 'center',
            sortable: false,
            value: 'sold',
          },
        ],
        data:[],
        series: [{
            name: 'Website Traffic',
            data: []
          },  {
            name: 'Purchase Page Traffic (Not Checkout)',
            data: []
          },
          {
            name: 'Ticket Sold',
            data: []
          },],
          chartOptions: {
            chart: {
              height: 500,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Website Traffic in Time Period',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: [],
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'Traffic'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          
          
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
  },
  methods: {
    async generateExcel(){
      var BASE_URL;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("API PRODUCTION ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API;
      } else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("API TEST ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_TEST;
      } else {
        console.log("API LOCAL ENVIRONMENT");
        BASE_URL = process.env.VUE_APP_BASE_URL_API_LOCAL;
      }

      let url = BASE_URL + 'v1/report/generate-excel?purpose=by-time'
      console.log(url);
        try{
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xlsx');
        document.body.appendChild(link);
        link.click();
        }
        catch(err){
          console.log(err)
        }
    },
    async generate(){
      let queryObject = [];
      let route = 'v1/traffic/by-time?'
      const response = await reportingApi.get(route, queryObject)
      const data = response.data.data;
      console.log(data);
      console.log([undefined, null].includes(data));
      if(![undefined, null].includes(data)){
        if(data.length > 0){
           data.forEach((item) => {
            console.log(item)
            this.chartOptions.xaxis.categories.push(item.time_period)
            this.series[0].data.push(item.website_traffic)
            this.series[1].data.push(item.purchase_page_traffic)
            this.series[2].data.push(item.sold)
            this.showChart = true
          })
          this.data = data;
          console.log(this.chartOptions)
          console.log(this.series)

        }
      }
    }
  },
};
</script>
